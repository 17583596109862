<template>
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col-md-6">
        <div class="card">
  <div class="card-body">

    <div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">ชื่อโครงงาน</label>
  <textarea type="text" class="form-control" id="exampleFormControlInput1" v-model="data.title"></textarea>
</div>
    <div v-for="s in liststu" :key="s.id">
    <div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">รหัสนิสิต</label>
  <input type="number" class="form-control" id="exampleFormControlInput1" v-model="s.no" disabled>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">ชื่อ-นามสกุล</label>
  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="s.name" disabled>
</div>
<!-- <a v-if="liststu.length > 1" @click="deletestu(s.id)" class="btn btn-danger btn-sm disabled"  style="color:white">ลบข้อมูลนิสิต</a> -->
<hr style="border: 1px solid black;">
</div>
    <!-- <a @click="addstu()" class="btn btn-sm btn-primary disabled" style="color:white">เพิ่มข้อมูลนิสิต</a> -->
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-6 mt-3">
    <div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">ชื่ออาจารย์ที่ปรึกษา</label>
  <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="data.advisor" >
  <option v-for="(i,r) in advisor" :key="r" :value="i.id">{{i.firstname}} {{i.lastname}}</option>
</select>
</div>
<div class="form-group mt-3">
                  <label for="username">ไฟล์เล่ม</label><br/>
                 
          <!-- <input id="file" class="form-control" type="file" accept=".pdf" @change="onFileChange"  /> -->
        <a :href="file[0].path" target="_blank" v-if="project">{{project}}</a> 
              </div>
              <div class="form-group mt-3">
                  <label for="username">ไฟล์โปรแกรม</label><br/>
                 
          <!-- <input id="file" class="form-control" type="file" accept=".pdf" @change="onFileChangeprogram"  /> -->
        <a :href="file[1].path" target="_blank" v-if="program">{{program}}</a> 
              </div>
              <div class="form-group mt-3" v-if="db">
                  <label for="username">ไฟล์DB</label><br/>
                 
          <!-- <input id="file" class="form-control" type="file" accept=".sql" @change="onFileChangedb"  /> -->
        <a :href="file[2].path" target="_blank" v-if="db">{{db}}</a> 
              </div>
</div>

    <a @click="save()" class="btn btn-sm btn-success mt-3 mb-3" style="color:white" v-if="projectId != 0">บันทึก</a>
  </div>
</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
      <h4>โครงงานปีพ.ศ.
        <div class="mb-3">
  <select class="form-select form-select-sm col-md-3" aria-label=".form-select-sm example" v-model="year" @change="onChangeyear()">
  <option v-for="(i,r) in years" :key="r" :value="i.year" :id="i.year">{{i.year}}</option>
</select>
</div></h4>
      <div v-if="list.length">
      <table class="table table-bordered mt-3">
        <thead>
          <tr class="table-active">
            <th scope="col">ชื่อโครงงาน</th>
            <th scope="col">ไฟล์เล่ม</th>
            <th scope="col">ไฟล์โปรแกรม</th>
            <th scope="col">ไฟล์ SQL</th>
            <th>Disabled</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr style="cursor: move" v-for="(l, i) in list" :key="i">
            <td style="width:30%">
              {{ l.title}}
            </td>
            <td style="width:10%">
              <a :href="l.project.path" target="_blank">{{l.project.name}}</a>
            </td>
            
            <td style="width:10%">
              <a :href="l.program.path" target="_blank">{{l.program.name}}</a>
            </td>
            <td style="width:10%">
              <a v-if="l.db" :href="l.db.path" target="_blank">{{l.db.name}}</a>
              <div v-else>-</div>
            </td>
            <td style="width:3%"><div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="l.status" @change="onChangeStatus(l)">
</div></td>
            <td style="width:3%"><a @click="getid(l.id)">
                <button
                  type="button"
                  class="btn btn-warning btn-md"
                >
                  <i class="fa fa-edit"></i></button
              ></a></td>
          </tr>
        </tbody>
      </table>
    </div>
      <div v-else class="mb-5" style="text-align:center">
          <h6>ไม่พบข้อมูล</h6>
    </div>
  </div>

</div>
  </div>
</template>

<script>
import ProjectUploadService from "../services/ProjectUploadService";
import LinkService from "../services/LinkService";
import axios from "axios";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      list: [],
      data: {},
      liststu: [],
      date: [],
      stuid: [],
      stulist: [],
      advisor: [],
      file: [
        { id: 1, type: "project", name: "", path: "" },
        { id: 2, type: "program", name: "", path: "" },
        { id: 3, type: "db", name: "", path: "" },
      ],
      project: "",
      program: "",
      db: "",
      year: "",
      projectId:0,
      years:[]
    };
  },
  mounted() {

    this.year = new Date();
    this.year = this.year.getFullYear() + 543;
    this.getadvisor();
    this.liststu.push({
      id: 1,
      no: "",
      name: "",
    });
    this.getprojects();
    this.getyears()
  },
  methods: {
    onChangeyear(){
      this.getprojects();
    },
    getyears(){
ProjectUploadService.getyears().then((res=>{
  this.years = res.data
  // console.log(res.data);
  // console.log(this.year);

  this.year = new Date();
    this.year = this.year.getFullYear() + 543;
}))
    },
    onChangeStatus(l){
console.log(l);
var status = {
  status:l.status
}
ProjectUploadService.updatestatus(l.id,status).then(()=>{
this.getprojects()
})
    },
    getid(id){
      if (id) {
        this.liststu = []
      this.data = {}
      this.projectId = id
      ProjectUploadService.getproject(id).then((res)=>{
        console.log(res.data);
        this.data = res.data
        var list = JSON.parse(this.data.stuId)
        this.project = res.data.project.name
this.file[0].path = res.data.project.path
this.program = res.data.program.name
this.file[1].path = res.data.program.path
if (res.data.db) {
  this.db = res.data.db.name
this.file[1].path = res.data.db.path
}

console.log(this.file);
        // console.log(list.length);
        for (let l = 0; l < list.length; l++) {
          // console.log(list[l]);
          ProjectUploadService.getstudent(list[l]).then((res)=>{
            // console.log(res.data);
            this.liststu.push({
      id: res.data.id,
      no: res.data.no,
      name: res.data.firstname_lastname,
    });
          })
        
        }
      })
      }else{
        this.projectId = 0
      }
     
    },
    getprojects() {
      ProjectUploadService.getprojects(this.year,1).then((res) => {
        console.log(res.data);
        this.list = res.data;
      });
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImage(files[0]);
    },
    onUploadFileImage(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.geturl();
      // console.log(this.data.type);
      // const regex = /([\u0E00-\u0E7F]+)/gmu;
      // const str = selectedFile.name;
      // let m;
      // // console.log(selectedFile.type);
      // let checkthai = "";
      let name = selectedFile.name;
      // let typefile = selectedFile.type.split("/");
      // while ((m = regex.exec(str)) !== null) {
      //   // This is necessary to avoid infinite loops with zero-width matches
      //   if (m.index === regex.lastIndex) {
      //     regex.lastIndex++;
      //   }

      //   // The result can be accessed through the `m`-variable.
      //   m.forEach(() => {
      //     // console.log(`Found match, group ${groupIndex}: ${match}`);
      //     checkthai = 1;
      //   });
      // }
      // if (checkthai == 1) {
      //   name = this.makeid(5) + "." + typefile[1];
      // }
      // console.log(name);
      var http = link + "/uploadimg?name=" + name + "&&path=thesis";
      var path = link + "/uploads/thesis/" + name;
      axios
        .post(http, formData)
        .then(() => {
          this.file.map((item) => {
            if (item.id === 1) {
              item.name = name;
              item.path = path;
            }
            this.project = name;
            console.log(this.file);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
},
    onFileChangeprogram(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImageprogram(files[0]);
    },
    onUploadFileImageprogram(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.geturl();
      // console.log(this.data.type);
      // const regex = /([\u0E00-\u0E7F]+)/gmu;
      // const str = selectedFile.name;
      // let m;
      // // console.log(selectedFile.type);
      // let checkthai = "";
      let name = selectedFile.name;
      // let typefile = selectedFile.type.split("/");
      // while ((m = regex.exec(str)) !== null) {
      //   // This is necessary to avoid infinite loops with zero-width matches
      //   if (m.index === regex.lastIndex) {
      //     regex.lastIndex++;
      //   }

      //   // The result can be accessed through the `m`-variable.
      //   m.forEach(() => {
      //     // console.log(`Found match, group ${groupIndex}: ${match}`);
      //     checkthai = 1;
      //   });
      // }
      // if (checkthai == 1) {
      //   name = this.makeid(5) + "." + typefile[1];
      // }
      // console.log(name);
      var http = link + "/uploadimg?name=" + name + "&&path=program";
      var path = link + "/uploads/program/" + name;
      axios
        .post(http, formData)
        .then(() => {
          this.file.map((item) => {
            if (item.id === 2) {
              item.name = name;
              item.path = path;
            }
            this.program = name;
            console.log(this.file);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileChangedb(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImagedb(files[0]);
    },
    onUploadFileImagedb(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.geturl();
      // console.log(this.data.type);
      // const regex = /([\u0E00-\u0E7F]+)/gmu;
      // const str = selectedFile.name;
      // let m;
      // // console.log(selectedFile.type);
      // let checkthai = "";
      let name = selectedFile.name;
      // let typefile = selectedFile.type.split("/");
      // while ((m = regex.exec(str)) !== null) {
      //   // This is necessary to avoid infinite loops with zero-width matches
      //   if (m.index === regex.lastIndex) {
      //     regex.lastIndex++;
      //   }

      //   // The result can be accessed through the `m`-variable.
      //   m.forEach(() => {
      //     // console.log(`Found match, group ${groupIndex}: ${match}`);
      //     checkthai = 1;
      //   });
      // }
      // if (checkthai == 1) {
      //   name = this.makeid(5) + "." + typefile[1];
      // }
      // console.log(name);
      var http = link + "/uploadimg?name=" + name + "&&path=db";
      var path = link + "/uploads/db/" + name;
      axios
        .post(http, formData)
        .then(() => {
          this.file.map((item) => {
            if (item.id === 3) {
              item.name = name;
              item.path = path;
            }
            this.db = name;
            console.log(this.file);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getadvisor() {
      ProjectUploadService.getadvisor().then((res) => {
        this.advisor = res.data;
        console.log(this.advisor);
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    deletestu(id) {
      for (let i = 0; i < this.liststu.length; i++) {
        if (this.liststu[i].id === id) {
          this.liststu.splice(i, 1);
          break;
        }
      }
    },
    save() {
      var statusstu = 1;
      console.log(this.liststu);
      for (let s = 0; s < this.liststu.length; s++) {
        if (
          this.liststu[s].no == "" ||
          this.liststu[s].no == null ||
          this.liststu[s].name == "" ||
          this.liststu[s].name == null
        ) {
          statusstu = 0;
        }
        if (this.liststu.length == s + 1) {
          if (statusstu == 0) {
            alert("กรุณากรอกข้อมูลนิสิตให้ถูกต้อง");
          } else {
            if (this.data.title == "" || this.data.title == null) {
              alert("กรุณากรอกชื่อโครงการ");
            } else if (this.data.advisor == "" || this.data.advisor == null) {
              alert("กรุณาเลือกชื่ออาจารย์ที่ปรึกษา");
            } else if (this.project == "" || this.project == null) {
              alert("กรุณาอัพโหลดไฟล์เล่ม");
            } else if (this.program == "" || this.program == null) {
              alert("กรุณาอัพโหลดไฟล์โปรแกรม");
            } else {
              if (this.projectId == 0) {
                
              for (let stu = 0; stu < this.liststu.length; stu++) {
                var stuarr = {
                  no: this.liststu[stu].no,
                  firstname_lastname: this.liststu[stu].name,
                };

                ProjectUploadService.createstu(stuarr).then((res) => {
                  this.stulist.push(res.data.id);
                  if (this.liststu.length == stu + 1) {
                    var bookarr = {
                      stuId: this.stulist,
                      title: this.data.title,
                      year: this.year,
                      advisor: this.data.advisor,
                      status: 1,
                    };
                    ProjectUploadService.createproject(bookarr).then(
                      (project) => {
                        var pj = project.data.id;
                        console.log(pj);
                        for (let f = 0; f < this.file.length; f++) {
                          if (this.file[f].name != "") {
                            var files = {
                              projectId: pj,
                              name: this.file[f].name,
                              path: this.file[f].path,
                              type: this.file[f].type,
                              status: 1,
                            };
                            console.log(files);
                            ProjectUploadService.createfile(files).then(() => {
                             
                            });
                           
                          }
                          if (f + 1 == this.file.length) {
                                this.$toast.success("บันทึกสำเร็จ", {
                                  timeout: 2000,
                                });
                                setTimeout(function () {
                                  location.reload();
                                }, 2000);
                              }
                        }
                      }
                    );
                  }
                });
              }
              }else{
                console.log(1);
                var updateproject = {
                      title: this.data.title,
                      advisor: this.data.advisor
                    };
                    console.log(this.projectId,updateproject);
                    ProjectUploadService.updateproject(this.projectId,updateproject).then(
                      () => {
                        this.$toast.success("บันทึกสำเร็จ", {
                                  timeout: 1000,
                                });
                                setTimeout(function () {
                                  location.reload();
                                }, 1000);
                      });
              }
            }
          }
        }
      }
    },
    addstu() {
      this.liststu.push({
        id: parseInt(this.liststu.length) + 1,
        no: "",
        name: "",
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: gray;
  border-color: gray;
}
</style>
